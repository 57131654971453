//https://gitlab.staging.makeo.fr/makeo-packages/mkongusers/-/blob/develop/projects/mkongusers/src/lib/interceptors/auth.interceptor.ts

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, filter, finalize, Observable, ReplaySubject, switchMap, take, throwError} from 'rxjs';

import {Router} from "@angular/router";
import {AuthService} from "@makeo-packages/mkongusers";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private nonAuthRoutes = [
    /^.*\/login_check$/g
  ];
  private nonAuthFrontRoute = [
    /^.*\/connexion$/g,
    /^.*\/inscription#.*$/g
  ]

  tokenSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  private isRefreshingToken: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    // /!\ erreur EmptyErrorImpl si on essaye de faire passer dans le contructeur :
    //   private authService: AuthService,   avec    import {AuthService} from "../services/auth.service"
    //   ou
    //   private authService: AuthCustomService,    avec class AuthCustomService extends AuthService de @makeo-packages/mkongusers
    // ... authService doit etre celui de from "@makeo-packages/mkongusers"
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> = this.createRequestWithToken(request);
    return next.handle(newReq).pipe(
      catchError((err: HttpErrorResponse) => {
        let message = this.extractErrorMessage(err);
        if(typeof message === 'undefined'){
          console.error('HTTP catch error undefined message on err=', err);
        }
        else{
          let matchRoute = this.nonAuthFrontRoute.filter(a => a.test(this.router.url));
          // alert("intercept() message:" + message);
          if ((message.includes("Jeton authentification non 2FA_OK")) && matchRoute.length == 0) {
            this.logout();
          }
          // correction page blanche chargement si token expiré
          if (message.includes("Expired JWT Token")) {
            return this.handleExpiredToken(next, request);
          }

          else if(err.status > 400 && err.status <= 403) {
            this.logout();
          }
          return throwError(() => err);
        }
      })
    );
  }

  private handleExpiredToken(next: HttpHandler, request: HttpRequest<any>) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject = new ReplaySubject<string>(1);
      // @ts-ignore
      return this.authService.newToken().pipe(
        switchMap((tokenResponse: any) => {
          if (tokenResponse && tokenResponse.token) {
            this.tokenSubject.next(tokenResponse.token);
            this.authService.accessToken = tokenResponse.token
            this.authService.refreshToken = tokenResponse.refresh_token
            return next.handle(request.clone({
              setHeaders: {Authorization: 'Bearer ' + tokenResponse.token}
            }));
          } else {
            window.location.reload();
            return throwError(() => new Error("No token received"));
          }
        }),
        catchError(error => {
          let matchRoute = this.nonAuthFrontRoute.filter(a => a.test(this.router.url));
          if (matchRoute.length == 0) {
            setTimeout(() => {
              this.router.navigate(['connexion'])
              this.authService.signOut()
            }, 1000);
          }
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {
      // If a refresh call is already in progress, queue the request until the token is refreshed
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(request.clone({
            setHeaders: {Authorization: 'Bearer ' + token}
          }));
        })
      );
    }
  }

  private logout()
  {
    setTimeout(() => {
      this.router.navigate(['connexion'])
      this.authService.signOut()
    }, 1000);
  }

  private extractErrorMessage(error: HttpErrorResponse) : string {
    return error?.error?.message ?? error.message;
  }

  private createRequestWithToken(request: HttpRequest<any>) {
    if (this.authService.accessToken != null) {
      return  request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.accessToken)
      });
    } else {
      return  request.clone();
    }
  }

}


