import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router, RouterOutlet} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {NtfyService} from "./services/ntfy.service";

import {AuthUserService} from "./services/auth-user.service";

import {AuthModule, AuthService} from "@makeo-packages/mkongusers";
import {filter, takeUntil} from "rxjs";
import {DestroyerService} from "@makeo-packages/mkongtools";
import {AlertService} from "./services/alert.service";
import {BookingSaverService} from "./services/booking-saver.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmModalComponent} from "./common-components/confirm-modal/confirm-modal.component";
import {AppointmentsService} from "./services/appointments.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, AuthModule],
})
export class AppComponent implements OnInit{

    /**
     * Constructor
     */
    constructor(
      private bookingSaverService: BookingSaverService,
      private authUserService: AuthUserService,
      private router: Router,
      private dialog: MatDialog,
      private appointmentService: AppointmentsService
    ) {

    }

  ngOnInit(): void {
      this.authUserService.userInfos().subscribe({
        next: value => {
          if(value)
          {
            if(this.bookingSaverService.isPendingBooking())
            {
              if(this.bookingSaverService.isBookingValid())
              {
                let saved = this.bookingSaverService.getSaved();

                this.dialog.open(ConfirmModalComponent, {
                  data: {
                    title: "Opération en cours",
                    confirmMessage: "Vous avez une " + (saved.context === 'rdv' ? " prise de RDV" : " demande de voyance privée") + " en cours.",
                    subTexts: [
                      "Voulez-vous reprendre là où vous en étiez ?"
                    ],
                    confirmButton: "Oui",
                    cancelButton: "Non"
                  },
                  disableClose: true
                }).afterClosed().subscribe({
                  next: val => {
                    if(val)
                    {
                      if(saved.context === 'rdv')
                      {
                        this.router.navigate(["prendre-rdv"], {
                          state: { save: saved }
                        })
                      }
                      else
                      {
                        this.router.navigate(["voyance-privee"], {
                          state: { save: saved }
                        })
                      }
                    }
                    else
                    {
                      if(saved.context === 'rdv')
                      {
                        this.appointmentService.unbookAppointmentToPlanning(saved.selectedSlot.id).subscribe({
                          next: () => {
                            this.bookingSaverService.clear();
                          }
                        })
                      }
                      else
                      {
                        this.bookingSaverService.clear();
                      }
                    }
                  }
                })
              }
              else
              {
                this.bookingSaverService.clear();
              }
            }
          }
        }
      })
  }
}
